import { createConsumer } from "@rails/actioncable"
consumer = createConsumer()

consumer.subscriptions.create "WebNotificationsChannel",
  received: (data) ->
    if(data['message'].length != 0)
      i = 0
      while i < data['message'].length
        content = ''
        if data['message'][i].n_type == 'job_posting'
          reason = "<a href='/job_postings'>" + data['message'][i].name + " has posted a new job. </a>"
        else
          if data['message'][i].url && data['message'][i].url.length > 0
            reason = '<a href="'+data['message'][i].url+'">'+data['message'][i].reason+'</a>'
          else
            reason = data['message'][i].reason

        if $('.notification-box').find('#notification-row-' + data['message'][i].id).length == 0
          content += '<div class="row notifi_div unread_notifi_div" id="notification-row-' + data['message'][i].id + '"> \
                        <div class="text-center">\
                          <img src="'+data['message'][i].image_url+'" class="rounded-circle">\
                        </div>\
                        <div class="col">\
                          <strong class="text-info text-capitalize">' + data['message'][i].name + '</strong><div class="notifi_content">' +
                          reason +  '</div>\
                          <small class="text-warning">' +
                          data['message'][i].created_at + '\
                          </small>\
                        </div>\
                      </div>'
          if $('.empty-notification-text').length != 0
            $('.notification-box').html(content)
          else
            $('.notification-box').prepend(content)
        i++
      # remove navAlert-counter
      $('.notification-bell-icon').remove('.navAlert-counter')
      $('.notification-bell-icon').prepend('<span class="navAlert-counter">' +
                                        data['message'][0].unread_msg + '</span>')
    else
      $('.nav-link').find('.navAlert-counter').remove()
      $('.notification-bell-icon').find('.navAlert-counter').remove()
