//
//
//
// Below code has also been duplicated to app/views/shared/scripts/_sticky_notes.html.erb
// If you are making changes to this file, then please update above file too.
// Note: The duplicated code is a patch to serve Js without Webpacker.
//
//
//

$(document).ready(function(){
  var text = $("#sticky-note-main-div").attr('data-sticky-text');
  if(text != null){
    $(".sticky-note-text").html(text)
  }
})

var acc = $(".sticky-note-accordion");
for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    if ($(this).hasClass('disableClick')) {
      $(this).removeClass('disableClick');
    }
    else{
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    }
  });
}

$( "#sticky-note-main-div" ).draggable({
  start: function(event, ui) {
    $(this).children(".sticky-note-accordion").addClass('disableClick');
  }
});

$(document).on('click', ".sticky-note-accordion", function(){  
  var userName = $("#sticky-note-accordion1").attr("data-name")
  if($(".sticky-note-accordion").hasClass('active')){
    $(".sticky-note-accordion").text("DNOTE - " + userName);
    $(".sticky-note-accordion").append("<span class='minimiz-and-maximize'>"+'<i class="fas fa-chevron-up"></i>'+'</span>');
    $("#sticky-note-main-div").css({'border' : '1px solid rgb(57 54 54)','z-index' : '10'});
    $(".sticky-note-accordion").css({'border-radius' : '15px 15px 0px 0px'})
  }
  else{
    $(".sticky-note-accordion").text("DNOTE - " + userName);
    $(".sticky-note-accordion").append("<span class='minimiz-and-maximize'>"+'<i class="fas fa-chevron-down"></i>'+'</span>');
    $(".sticky-note-accordion").css({'border-radius' : '15px 15px 15px 15px'})
  }
})

$(document).on('click', ".hide-stiky-note-div", function(){
  $("#sticky-note-main-div").removeClass('hide')
})

$(document).on('click', ".sticky-note-save-btn", function(){
  $("#sticky-note-main-div").addClass('hide')
})
