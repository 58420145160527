$(document).on 'click', '.send-summary-mail-btn', (e)->
  e.preventDefault()
  if($(this).closest('form')[0].checkValidity())
    $('#loader-cont').fadeIn()
  else
    $(this).closest('form').validate().element(".rr-email-address")
  Rails.fire($(this).closest('form')[0], 'submit')
  return false

# UMS summary reports list for showing comments
$(document).on 'click', '.show-sum-rep-com-popup', () ->
  $(this).siblings('.modal').modal('show')