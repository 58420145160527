//
//
//
// Below code has also been duplicated to app/views/shared/scripts/_user_progression.html.erb
// If you are making changes to this file, then please update above file too.
// Note: The duplicated code is a patch to serve Js without Webpacker.
//
//
//

$(".progression-accordion").click(function(){
  var panel = this.nextElementSibling;
  if (panel.style.display === "block") {
      panel.style.display = "none";
    } 
    else {
      panel.style.display = "block";
    }
})
$(document).ready(function(){
  $(".file-btn-color-2").hide()
  var openAccordian = localStorage.getItem("open-accordions")
  var arr = []
  if (openAccordian != null){
    openAccordian = openAccordian.split(",");
    openAccordian.forEach(function(item){
      $(`.minister-accorddion-${item}`).next().css({'display' : 'block'})
    })
    arr = openAccordian
  }
  var accordion = $(".progression-accordion");
  for (i = 0; i < accordion.length; i++) {
    accordion[i].addEventListener("click", function() {
      var id = $(this).data("id")
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      icon = $(this).children(".user-down-arrow").children(".user-down-arrow-2")
      if (panel.style.display === "block") {
        arr.splice(arr.indexOf(id.toString()), 1);
        panel.style.display = "none";
        icon.removeClass("fas fa-chevron-up")
        icon.addClass("fas fa-chevron-down")
      } else {
        arr.push(id.toString())
        icon.removeClass("fas fa-chevron-down")
        icon.addClass("fas fa-chevron-up")
        panel.style.display = "block";
      }
      localStorage.setItem("open-accordions", arr)
    });
  }

  $(".honorable, .dishonorable").on("change", function(event){
    if (this.checked == true) {
      var box= confirm("This action will retire this MIN immediately and make changes to their current locations. This is best done following their actual retirement date which is available from Secretariat. Are you sure you wish to do this now?");
      if (box==true){
        return true;
      }
      else{
        event.preventDefault();
        event.stopPropagation();
        this.checked = false
      }
    }
  })

  $(".retired-remove").on("change", function(event){
    if (this.checked == true) {
      var box= confirm("This action will remove this MIN immediately from the system.  All data associated to this MIN will be removed. Are you sure you wish to do this now?");
      if (box==true){
        $('#loader-cont').show()
        return true;
      }
      else{
        event.preventDefault();
        event.stopPropagation();
        this.checked = false
      }
    }
  })

  var text = $(".reminder_message-div").attr('reminder-text');
  if(text != null){
    $(".reminder_message-text").html(text)
  }
  
  $(".clickable").click(function(){
    location.href = $(this).closest('tr').data('href');
  });
  
  $(document).on("click", ".send-reminder-button", function(){
    $('#review-submission-reminder').modal("hide");
  })

  $(document).on("click", ".progressions-tab", function(){
    localStorage.removeItem("open-accordions")
  })
  
  $(document).on("click", ".upload-profile-btn", function(event){
    var id = $(this).attr('data-id')
    $(`#upload-profile-file-${id}`).click();
  })
  
  $(document).on('change', '#upload-profile-from',function(){
    $(this).children(".form-submit-btn").click()
    if ($(this).children('.upload-profile-btn').length == 1){
      location.reload();
    }
  })
  
  $(document).on('keyup', '.progression-lic-notes',function(){
    var notes = $(this).val()
    var id = $(this).attr('data-id')
    url = $(this).attr('data-url')
    text = $(this).attr('lic-notes-text')
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        notes: notes,
        user_id: id
      }
    });
  })

  $(document).on('change', '.practical-req',function(){
    var id = $(this).attr('data-id')
    url = $(this).attr('data-url')
    practicalReq = $(this).is(':checked')
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        practical_requirement: practicalReq,
        user_id: id
      }
    });
  });

  $(document).on('change', '.theological-req',function(){
    var id = $(this).attr('data-id')
    url = $(this).attr('data-url')
    theologicalReq = $(this).is(':checked')
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        theological_requirement: theologicalReq,
        user_id: id
      }
    });
  });

  $(document).on('change', '.honorable-retirement',function(){
    var id = $(this).attr('data-id')
    url = $(this).attr('data-url')
    retirementIntent = $(this).attr('retirment-intent')
    checked = $(this).is(':checked')
    if($(this).hasClass("honorable") && checked){
      $(`#dishonorable-retirement-${id}`).attr('disabled', 'disabled');
    }
    else if($(this).hasClass("dishonorable") && checked){
      $(`#honorable-retirement-${id}`).attr('disabled', 'disabled');
    }
    else if($(this).hasClass("honorable") && checked == false){
      $(`#dishonorable-retirement-${id}`).removeAttr("disabled");
    }
    else if($(this).hasClass("dishonorable") && checked == false){
      $(`#honorable-retirement-${id}`).removeAttr("disabled");
    }
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        checked: checked,
        retirement_intent: retirementIntent,
        user_id: id
      }
    });
  });
  
  $('.file-delete-icon').click(function(){
    $(this).addClass('hide')
    $(this).parent().children('.panel__left__row__box--body :first').next().addClass('hide')
    buttonClass = $(this).parents(".align-items-center").children(".form-class")
    buttonClass.children('.upload-profile-btn ').removeClass('file-btn-color-2')
    buttonClass.children('.upload-profile-btn ').show()
  })

  $(".expand-all-accordian").click(function(){
    if($(".expand-all-accordian").hasClass("expand-all-accordian")){
      $(".panel").css({'display' : 'block'})
      $(this).addClass('close-all-accordian')
      $(this).removeClass('expand-all-accordian')
      $(this).html("CLOSE ALL")
    }
    else{
      localStorage.removeItem("open-accordions")
      $(".panel").css({'display' : 'none'})
      $(this).removeClass('close-all-accordian')
      $(this).addClass('expand-all-accordian')
      $(this).html("EXPAND ALL")
    }
  })

  $(".progression-minister-search-form").keypress(function (e) {
    if (e.keyCode == 13) {
      $('.progression-minister-search-form').submit();
    }
  })

  $(document).on("change",".interview-statuse-div", function(){
    if ($(this).find(":selected").val() == "Has Now Been ORD/ COM"){
      message = confirm("This will move this Minister from Licensed Ministry to the Ordained/ Commissioned Pastors list, Please comfirm you wish to do this.");
      if(message) {
        return true;
      } else {
       return false;
      }
    }
  })

})

$(".send-request-btn").on("click", function(){
  $('#report-view-request-modal').modal("hide");
  $('body').removeClass('modal-open');
  $('.modal-backdrop').remove();
})
