$(document).on('click', '.form-signin, .edit-profile-form', function (e) {
  e.preventDefault()
  _form = $(this).closest('form');
  if (_form.valid() &&
    ($('.mentor-detail').hasClass('hide-and-disable') ||
      (_form.find('#uploadFile')[0].files.length > 0 || _form.find('.user-image img').hasClass('avatar-selected'))
    )
  ) {
    _form.submit();
  }else {
    if (_form.find('#uploadFile')[0].files.length < 1)
      if(!$('#uploadTrigger').hasClass('error')) {
        $('#uploadTrigger').toggleClass('error')
        $('#uploadTrigger-error').toggleClass('error')
        $('#uploadTrigger-error').removeClass('visibility-hidden')
      }
  }
});

$(document).ready(function () {
  //========= Jquery validation ===========
  $.validator.addMethod("usernameRegx", function (value, element, regexpr) {
    return regexpr.test(value);
  }, "Only allowed letter, number, underscore.");

  $.validator.addMethod("emailRegx", function (value, element, regexpr) {
    return regexpr.test(value);
  }, "Please enter a valid email address.");

  $('.custom-form-validation').validate({
    rules: {
      "user[username]": {
        required: true,
        usernameRegx: /^[a-zA-Z0-9_.]*$/
      },
      "user[password]": {
        required: ($('.edit-profile-pass').length > 0) ? false : true,
        minlength: 12
      },
      "user[email]": {
        required: true,
        emailRegx: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    }
  });
});

// ====== Activity Status bg color =======
$(document).on('click', '.activity-status-dropdown', function() {
  $(this).removeClass('not_stated in_progress completed discontinued');
  $(this).addClass($(this).val());
});

$(document).on('click', '.act_rev_rat_button', function(e) {
  e.preventDefault();
  season_invalid = false;
  status_invalid = false;
  var current_element = $(this);
  var form = current_element.closest('.activities_status_season_form');
  form.find('.act-season').each(function() {
    if($(this).val().length == 0) {
      season_invalid = true;
      $('.error-box .err-seasons').removeClass('hide');
    }else {
      season_invalid = false;
      $('.error-box .err-seasons').addClass('hide');
    }
  })
  form.find('.act-status').each(function() {
    if($(this).val().length == 0) {
      status_invalid = true
      $('.error-box .err-statuses').removeClass('hide');
    }else {
      status_invalid = false
      $('.error-box .err-statuses').addClass('hide');
    }
  })
  if(!season_invalid && !status_invalid )
    Rails.fire($('.activities_status_season_form')[0], 'submit');
});

// ============ Notification Bell ==============
$(document).on('click', 'a.notification-bell-icon', function(e){
  e.preventDefault();
  e.stopPropagation();
  var parent = $(this).closest('.dropdown')
  if( $(this).attr("aria-expanded") == 'true'){
    $(this).attr("aria-expanded", false)
    parent.removeClass('show');
    parent.find('ul.dropdown-menu').removeClass('show');
  }else {
    $(this).attr("aria-expanded", true)
    parent.addClass('show');
    parent.find('ul.dropdown-menu').addClass('show');
    Rails.ajax({
      url: '/notifications/update_all',
      type: 'PUT'
    })
  }
});
$(document).on('click', 'a.update-bell-icon', function(e){
  e.preventDefault();
  e.stopPropagation();
  var parent = $(this).closest('.dropdown')
  if( $(this).attr("aria-expanded") == 'true'){
    $(this).attr("aria-expanded", false)
    parent.removeClass('show');
    parent.find('ul.dropdown-menu').removeClass('show');
  }else {
    $(this).attr("aria-expanded", true)
    parent.addClass('show');
    parent.find('ul.dropdown-menu').addClass('show');
    Rails.ajax({
      url: '/notifications/read_all_updates',
      type: 'PUT'
    })
  }
});


// =========== Tab Switching ============
function customTabSwitching() {
  var tabNumber = 0;
  $(document).on('keydown', function (e) {
    var keyCode = e.keyCode || e.which;
    var ele = document.activeElement;
    if (tabNumber < parseInt($('.tab-pointer').last().attr('tabindex'))) {
      tabNumber = parseInt($(ele).attr('tabindex'))
    };
    tabNumber += 1;
    nextTabNumber = '';
    prevTabNumber = '';
    if (e.shiftKey && keyCode == 9) {
      e.preventDefault();
      $('.tab-pointer:visible').each(function (index, currentElement) {
        if ($(currentElement).attr('tabindex') == $(ele).attr('tabindex')) {
          prevTabNumber = index - 1;
          if (index == 0) {
            prevTabNumber = $('.tab-pointer:visible').length - 1;
          }
        }
      })
      $($('.tab-pointer:visible')[prevTabNumber]).focus();
    } else if (!e.shiftKey && keyCode == 9) {
      e.preventDefault();
      $('.tab-pointer:visible').each(function (index, currentElement) {
        if ($(currentElement).attr('tabindex') == $(ele).attr('tabindex')) {
          nextTabNumber = index + 1;
          if (index == ($('.tab-pointer:visible').length - 1)) {
            nextTabNumber = 0;
          }
        }
      })

      $($('.tab-pointer:visible')[nextTabNumber]).focus();
    }
  })
}

// ========= Vision & Goal ===========
$(document).on('click', '.add-more-sample-vision-goals', function(e) {
  e.preventDefault();
  var parent_target = $(this).parent().siblings('.overflow_table');
  var target = parent_target.find('.vision_goal_table tbody');
  var type_name = $(this).attr('data-type-name');
  target.append(generate_vision_goal_sample(type_name))
});

function generate_vision_goal_sample(type_name) {
  content = '<tr> \
              <td> \
                <textarea rows="6" name="content['+ type_name +'][smart_goal1][]" id="content[horizon][smart_goal1][]"></textarea> \
              </td> \
              <td> \
                <textarea rows="6" name="content['+ type_name +'][smart_goal2][]" id="content[horizon][smart_goal1][]"></textarea> \
              </td>\
              <td> \
                <textarea rows="6" name="content['+ type_name +'][smart_goal3][]" id="content[horizon][smart_goal1][]"></textarea> \
              </td> \
              <td> \
                <textarea rows="6" name="content['+ type_name +'][smart_goal4][]" id="content[horizon][smart_goal1][]"></textarea> \
              </td> \
            </tr>'
  return content
}

// ======== Report Vision Goal ==========
$(document).on('click', '.add-more-report-vg-field', function (e) {
  e.preventDefault();
  var parent_target = $(this).closest('.foreground-add-more-cont').siblings('.overflow_table');
  var target = parent_target.find('.vision_goal_table tbody');
  var type_name = $(this).attr('data-type-name');
  var indx = target.find('textarea').last().attr('data-loc-indx');
  var report_id = target.find('textarea').last().attr('data-report-id');
  var dataTextareaArray = target.find('textarea').last().attr('data-textarea-selector').split('-');
  var dataTextareaRowNumber = parseInt(dataTextareaArray[dataTextareaArray.length - 2]) + 1;
  target.append(generate_report_vision_goal_fields(type_name, indx, report_id, dataTextareaRowNumber))
});
function generate_report_vision_goal_fields(type_name, indx, report_id, dataTextareaRowNumber) {
  content = '<tr> \
              <td> \
                <textarea data-loc-indx='+ indx +' name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal1][]" rows="6"></textarea> \
                <textarea data-textarea-selector="report_vision_goals-'+indx+'-'+type_name+'-smart_goal1_status-'+dataTextareaRowNumber+'-0" name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal1_status][]" rows="1" data-loc-indx="'+ indx +'" class="d-none" id="report_report_vision_goals_attributes_'+ indx +'_tmp_content">status_grey </textarea> \
                <a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal1_status-'+dataTextareaRowNumber+'-0"></a> \
                <div class="status_color_picker badge status_grey"><a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal1_status-'+dataTextareaRowNumber+'-0"></a></div> \
              </td> \
              <td> \
                <textarea data-loc-indx='+ indx +' name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal2][]" rows="6"></textarea> \
                <textarea data-textarea-selector="report_vision_goals-'+indx+'-'+type_name+'-smart_goal2_status-'+dataTextareaRowNumber+'-1" name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal2_status][]" rows="1" data-loc-indx="'+ indx +'" class="d-none" id="report_report_vision_goals_attributes_'+ indx +'_tmp_content">status_grey </textarea> \
                <a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal2_status-'+dataTextareaRowNumber+'-1"></a> \
                <div class="status_color_picker badge status_grey"><a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal2_status-'+dataTextareaRowNumber+'-1"></a></div> \
              </td>\
              <td> \
                <textarea data-loc-indx='+ indx +' name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal3][]" rows="6"></textarea> \
                <textarea data-textarea-selector="report_vision_goals-'+indx+'-'+type_name+'-smart_goal3_status-'+dataTextareaRowNumber+'-2" name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal3_status][]" rows="1" data-loc-indx="'+ indx +'" class="d-none" id="report_report_vision_goals_attributes_'+ indx +'_tmp_content">status_grey </textarea> \
                <a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal3_status-'+dataTextareaRowNumber+'-2"></a> \
                <div class="status_color_picker badge status_grey"><a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal3_status-'+dataTextareaRowNumber+'-2"></a></div> \
              </td> \
              <td> \
                <textarea data-loc-indx='+ indx +' name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal4][]" rows="6"></textarea> \
                <textarea data-textarea-selector="report_vision_goals-'+indx+'-'+type_name+'-smart_goal4_status-'+dataTextareaRowNumber+'-3" name="report[report_vision_goals_attributes]['+ indx + '][content][' + type_name +'][smart_goal4_status][]" rows="1" data-loc-indx="'+ indx +'" class="d-none" id="report_report_vision_goals_attributes_'+ indx +'_tmp_content">status_grey </textarea> \
                <a class="delete-foreground-row" href="javascript:void(0)"><button name="button" type="button" class="close px-2 close-foreground-row">x</button></a>\
                <a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal4_status-'+dataTextareaRowNumber+'-3"></a> \
                <div class="status_color_picker badge status_grey"><a class="btn btn-block 1a-actions" data-toggle="modal" data-target="#myModal" title="Not Started" data-remote="true" href="/reports/'+ report_id +'/change_status_model?attr_name=report_vision_goals-'+indx+'-'+type_name+'-smart_goal4_status-'+dataTextareaRowNumber+'-3"></a></div> \
              </td> \
            </tr>'
  return content
}

// ======= AP Guide =========
// $('.empty-ap-guide').click(function(e) {
$(document).on('click', '.empty-ap-guide', function (e) {
  e.preventDefault();
  toastr.error('Guide not uploaded yet.');
});

// ======= 2B remove row functionality ======
$(document).ready(function(){
  $('.new_job_posting').validate({
    rules: {
      "job_posting[message]": {
        required: true
      }
    }
  });
});

// ===== Accordion toggle on params ======
$(document).on('click', '.accordion-card-cont', function () {
  var accordId, newUrl, queryString, ref, url;
  if (!$(this).find('.card-header').hasClass('collapsed')) {
    accordId = $(this).data('id');
    // ref will have [val1, val2], url == val1 & queryString == val2
    ref = location.href.split('?'), url = ref[0], queryString = ref[1];
    newUrl = url + '?accordion_id=' + accordId;
    return history.pushState({}, '', newUrl);
  }
});

// ===== open dropdown for single record in list, add class to remove css issue of dropdown
// ===== dropdown open table row scroll when table contain single row.
$(document).on('click', '.trigger-dropdown-dependent-class', function() {
  parent = $(this).closest('.dropdown-dependent-class');
  parent.addClass('dropdown-relative-position-effect');
});


// any element having class acts-as-link and data-url will act as a link tag
$(document).on('click', '.acts-as-link', function() {
  if($(this).data('url').length > 0) {
    location.href = $(this).data('url');
  }
});
