//
//
//
// Below code has also been duplicated to app/views/shared/scripts/_induction.html.erb
// If you are making changes to this file, then please update above file too.
// Note: The duplicated code is a patch to serve Js without Webpacker.
//
//
//

$(document).ready(function() {
  // Handle accordion click with delegation
  $(document).on("click", ".induction-accordion", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    var icon = $(this).children(".user-down-arrow").children(".user-down-arrow-2");
    if (panel.style.display === "block") {
      panel.style.display = "none";
      icon.removeClass("fas fa-chevron-up").addClass("fas fa-chevron-down");
    } else {
      panel.style.display = "block";
      icon.removeClass("fas fa-chevron-down").addClass("fas fa-chevron-up");
    }
  });

  // Handle row clicks with delegation
  $(document).on("click", ".induction-row", function() {
    const url = $(this).closest("tr").data("href");
    if (url) {
      location.href = url;
    }
  });

  // Initialize Select2 for static elements
  $('.induction-ministers-dropdown').select2({
    theme: 'bootstrap'
  });

  $('.other-selectors').select2({
    minimumResultsForSearch: -1,
    theme: 'bootstrap'
  });
});
