#===== resource sidebar filters =======
$ ->
  if($('#custom-resources').length > 0 )
    # toggleFieldsResourceType($('#resource_resource_type_id'))
    # populate_dynamic_columns($('#resource_resource_type_id').val(), $('#hidden-resource-id').val())
    populate_ministers('#resource_resource_type_id')

  # populate competency tags
  if($('.compitContainer .styled-checkbox').length > 0)
    populateCompetencyTags($('.compitContainer .styled-checkbox'))

$(document).on 'click', '#reset-search-bar', ->
  $('#search_resource').val('')
  $(this).closest('form').submit()

$(document).on 'click', '.competency-checkbox', ->
  # uncheck all competencies and then checked clicked one
  checked = $(this).prop('checked')
  $('.competency-checkbox').each( (indx, elem) ->
    $(elem).prop('checked', false)
  )
  $('.competency-tags-checkbox').each( (indx, elem) ->
    $(elem).prop('checked', false)
  )

  # SlideDown for competency tags filters if present
  # slideUp when unchecked or checked any other checkbox
  if(checked)
    $('.competency-tags-list').slideUp(100)
    tags = $(this).siblings('.competency-tags-list').find('.resource_siderbar_list').children()
    if(tags.length > 0)
      $(this).siblings('.competency-tags-list').slideDown(300)
  else
    $(this).siblings('.competency-tags-list').slideUp(300)

  $(this).prop('checked', checked)
  return

$(document).on 'click', '.search_checkbox', ->
  $('#resource-list-cont').css(
    'opacity': 0.4
  )
  form = $(this).closest('form')
  Rails.fire(form[0], 'submit')
  return

$(document).on 'keypress', '#search_resource', (e) ->
  if e.keyCode == 13
    $('#resource-list-cont').css(
      'opacity': 0.4
    )
    # $(this).closest('form').submit()
    form = $(this).closest('form')
    Rails.fire(form[0], 'submit')
    return false
$(document).on 'change', '#sort_by_av_rating', ->
  # $(this).closest('form').submit()
  $('#resource-list-cont').css(
    'opacity': 0.4
  )
  form = $(this).closest('form')
  Rails.fire(form[0], 'submit')
  return false

#===== populate mentor listing on resource type change =======
$(document).on 'change', '#resource_resource_type_id', ->
  # toggleFieldsResourceType($(this))
  # populate_ministers(@)
  populate_dynamic_columns($(this).val(), $('#hidden-resource-id').val())

#===== populate mentor listing on resource type change =======
$(document).on 'change', '#mentor_selection', ->
  handle_minisiter_selection(@)

#===== Resource Detail Review =========
$(document).on 'click', '.flag-reason-link', (e) ->
  e.preventDefault()
  current_element = $(this)
  parent = current_element.closest('.review-list')
  parent.find('.flag-reason-cont').each (indx, element) ->
    if !$(element).hasClass('hide') && current_element.siblings('.flag-reason-cont')[0] != element
      $(element).toggleClass 'hide'
    return
  $(this).siblings('.flag-reason-cont').toggleClass 'hide'
  return

$(document). on 'click', '.edit-review-link', (e)->
  e.preventDefault();
  parent = $(this).closest('.reviewResource_review')
  parent.find('.resource_review_comment').toggleClass('hide')
  parent.find('.resource_review_comment_form').toggleClass('hide')
  parent.find('.r_review_smbtn').toggleClass('hide')
  return

#========= Competency Tags selection in resources form ========
$(document).on 'click', '.compitContainer .styled-checkbox', ->
  populateCompetencyTags($(this))
  return

#========= Functions ==========
handle_minisiter_selection = (target) ->
  value = $(target).val()
  if value != ''
    $.ajax
      type: 'GET'
      url: '/mentor_details'
      data: {mentor_id: value}

populate_ministers = (target) ->
  $selector = $(target).find('option:selected')
  value = $selector.text().toLowerCase()
  $('#mentor_selection').parent().hide()
  if value == 'mentors' || value.includes('mentors')
    $.ajax
      type: 'GET'
      url: '/mentor_listing'
      data: {resource_type_id: $selector.val()}

# === Populate required fields according to resource_type select ====
toggleFieldsResourceType = (resource_type) ->
  $('.rt-effective-block').hide()
  selected = resource_type.children(':selected').text().toLowerCase()
  if( resource_type.val().toString().length != 0 )
    $('.rt-effective-block').each( (indx, elem) ->
      if ( $(elem).hasClass('all-show' ) )
        $(elem).show()
      if( $(elem).hasClass(selected + '-show' ) )
        $(elem).show()
      if( $(elem).hasClass(selected + '-hide' ) )
        $(elem).hide()
    )

# =====
populate_dynamic_columns = (resource_type_id, resource_id) ->
  $('#resource-form-inner-container').css('opacity', 0.3)
  $.ajax
    type: 'GET'
    url: '/resources/'+ resource_id + '/fetch_dynamic_columns'
    data: {'resource_type_id': resource_type_id}
    dataType: 'script'
  return false

# ===== Resource form, competency tags populate according to competency selected

populateCompetencyTags = (target_element) ->
  form = target_element.closest('form')
  checkboxes = form.find('.competency-checkboxes:checked');
  competency_ids = $.map(checkboxes, (elem)->
   return $(elem).val()
  )
  resource_id = form.find('input[name=resource_id]').val()
  $.ajax(
    url: '/RL/competencies/fetch_tags'
    data: { ids: competency_ids, resource_id: resource_id }
    dataType: 'script'
  )
  return