$(document).on 'click', '#review-list-cont .review-tr-cont td:not(:last-child)', ->
  location.href = $(this).parent().attr('data-href') if $(this).parent().attr('data-href').length > 0
  return false
# $(document).on 'click', '.rate-showcase', ->
#   $(this).siblings('.rate-slide-bar').css("display", "flex").hide().fadeIn()
#   return false
$(document).on 'click', '.rate-slide-close', ->
  $(this).parent().fadeOut()
  return false
$(document).on 'click', '.invite-reviewer-btn', ->
  if($('#rinvite-email-field')[0].checkValidity())
    $('#loader-cont').fadeIn()
  else
    $(this).closest('form').validate().element("#rinvite-email-field")
  Rails.fire($(this).closest('form')[0], 'submit')
  return false
$(document).on 'click', '.reminder-link:not(".reminder-disable-color")', (e) ->
  e.preventDefault()
  $('#loader-cont').fadeIn()
  $.ajax(
    url: $(this).attr('data-href')
    type: 'GET'
  ).done (data) ->
    toastr.success('Reminder has been sent.');
    $('#reminder-'+data.reminders+'-id-'+data.id).parent().addClass('reminder-disable-color');
    $('#loader-cont').fadeOut();
$(document).on 'click', '.moderate-review-tr td:not(".right-border")', ->
  location.href = $(this).parent().attr('data-href')

# ==== Preview Competency Report PGS, MIN
$(document).on 'click', '.preview-competency_report_tr', ->
  window.open($(this).attr('data-href'), '_blank')

# ===== Does not apply Rate ========
$(document).on 'click', '.does-not-apply-rate', ->
  doesNotApplyRate($(this))
  return false
$(->
  $('.does-not-apply-rate.run-click-trigger').each((indx, element) ->
    doesNotApplyRate($(element))
  )
)

# ======= Toggle Generate Review Report btn according to sidebar tab active ========

$(document).on 'click', '.toggle-generate-review-report', ->
  parent = $(this).closest('.quest_view_sidebar_list')
  # check if we click last tab of sidebar or not, if yes return true otherwise false
  last_sidebar_tab = parent.find('a.toggle-generate-review-report').last().attr('id') == $(this).attr('id')
  toggle_class = if $(this).closest('.invitee-review-report-form').length > 0 then '.submit-reviewer-report-container' else '.generate-review-report-container'
  if(last_sidebar_tab)
    $(toggle_class).show()
  else
    $(toggle_class).hide()
  return

doesNotApplyRate = (current_element) ->
  parent = current_element.parent()
  slideBar = parent.find('.rate-slide-bar')
  rateBox = parent.find('.rate-showcase')
  slideBar.toggleClass('custom-disable')
  rateBox.toggleClass('custom-disable')
  if current_element.text() == "Can't say / Does not apply"
    current_element.text('Apply')
    parent.find('.review-rating').val(null);
  else
    current_element.text("Can't say / Does not apply")

#======= Auto save review form =============
timer = undefined
$(document).on 'input', '.quest_review textarea', ->
  autoSaveReviewForm()
  return
# $(document).on 'click', '.rateYo-cont, .does-not-apply-rate', ->
#   console.log('sdfdsf');
#   autoSaveReviewForm()
#   return
$(->
  if $('.quest_review').length > 0
    observer = new MutationObserver((mutations) ->
      mutations.forEach (mutation) ->
        if mutation.type == 'childList'
          autoSaveReviewForm()
        return
      return
    )
    observer.observe($('.quest_review')[0],
      childList: true
      subtree: true
    )
)
autoSaveReviewForm = ->
  clearTimeout timer
  timer = setTimeout((->
    methodType = $('#review-form-tag, .invitee-review-report-form').find('input[name=_method]').val()
    if(methodType == undefined)
      methodType = 'POST'
    $.ajax
      url: $('#review-form-tag, .invitee-review-report-form').attr('action')
      type: methodType
      data: $('#review-form-tag, .invitee-review-report-form').serialize()
      dataType: 'json'
      beforeSend: (xhr) ->
        xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
        $('.autosave-textbox').html 'Saving...'
        $('.auto-save-cont').css('display', 'flex')
        return
      complete: ->
        setTimeout (->
          $('.autosave-textbox').html 'Saved!'
          return
        ), 1000
        setTimeout (->
          $('.autosave-textbox').html '&nbsp;'
          $('.auto-save-cont').css('display', 'none')
          return
        ), 2000
        return
    return
  ), 2000)
  return

$(document).ready ->
  if $('.quest_view_sidebar_list li.active-tab a').attr('id') == 'envisioning-cont-tab'
    $('.next-question-btn').hide()
  $('body').on 'click', '.next-question-btn', ->
    next_tab = $('.quest_view_sidebar_list').find('li.active-tab').next('li')
    $(next_tab).find('a').trigger 'click'
    $(next_tab).trigger 'click'
    $('html, body').animate { scrollTop: 0 }, 'slow'
    return
  $('body').on 'click', '.quest_view_sidebar_list li', ->
    if $(this).find('#envisioning-cont-tab').length > 0
      $('.next-question-btn').hide()
    else
      $('.next-question-btn').show()
    return
  return

# ====== Moderate Review Detail =========

# set selected-competency-hidden field value according to competency active tab
$(document).on('click', '#review-report-form .add-current-competency-urlstate', ->
  $('.selected-competency-hidden').val($(this).data('url-state-change') )
)