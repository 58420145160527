$(document).on 'click', '#reivew-next-confirmation', ->
  parent = $(this).closest('.modal-content')
  parent.hide()
  parent.siblings('.modal-content').show()
  return false

#==== Min avail timeslot
$(document).on 'click', '#yes-avail-timeslot', ->
  event_id = $('#event-id-field').val()
  year = $('#review-year-selection').val()
  location_ids = $('#review-location-selection').val()
  $.ajax(
    url: '/schedule_times/'+event_id+'/appointments',
    dataType: 'script',
    type: 'POST',
    data: { year: year, location_ids: location_ids }
    beforeSend: (xhr) ->
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content') )
  )
  return false

#==== Change locations according to year
$(document).on 'change', '#review-year-selection', ->
  year = $('#review-year-selection').val()
  $.ajax(
    url: '/appointments/location_all',
    dataType: 'script',
    type: 'GET',
    data: { year: year }
    beforeSend: (xhr) ->
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content') )
  )
  return false

#==== Setting Appointment popup, enable btn when year and locations selected
$(document).on 'change', '#review-year-selection, #review-location-selection', ->
  parent = $(this).closest('.modal-content')
  year_location_fields = parent.find('#review-year-selection, #review-location-selection')
  any_field_empty = false
  year_location_fields.each( (indx, elem) ->
    if $(elem).val().length == 0
      any_field_empty = true
  )
  if any_field_empty
    parent.find('#reivew-next-confirmation').attr('disabled', true)
  else
    parent.find('#reivew-next-confirmation').attr('disabled', false)
  return false