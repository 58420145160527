/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Import jQuery first
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// Rails-related libraries
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
// import Turbolinks from "turbolinks"

Rails.start();
ActiveStorage.start();
// Turbolinks.start()

// Expose functions globally
window.Rails = Rails;
window.toastr = toastr;

// NPM Modules
import "popper.js";
import "bootstrap";
import "jquery-ui";
import "jquery-datetimepicker";
import "jquery-minicolors";
import "bootstrap-slider";
import "toastr/toastr";
import "moment";
import "fullcalendar";
import "@nathanvda/cocoon";
import "enjoyhint";
import "jquery-ui/dist/jquery-ui";
import "select2/dist/js/select2.full.min.js";
import "multiple-select/dist/multiple-select.min.js";
import "jquery-validation";
import "rateyo";
import "bootstrap-toggle";
import "./nested_form";

// Custom JS Imports
import "../src/bootstrap-tagsinput";
import "../src/jquery_periodicalupdater";
import "../src/jquery_updater";
import "../src/app_custom";
import "../src/outsideclick";
import "../src/custom-audio-player";
import "../src/yearpicker";
import "../src/profile";
import "../src/report_expansion";
import "../src/user_progression";
import "../src/sticky_notes";
import "../src/report_reminder";
import "../src/principals";
import "../src/induction";

// CoffeeScripts
import "../src/coffeescripts/interns.coffee";
import "../src/coffeescripts/users.coffee";
import "../src/coffeescripts/resources.coffee";
import "../src/coffeescripts/ministers.coffee";
import "../src/coffeescripts/activities.coffee";
import "../src/coffeescripts/report.coffee";
import "../src/coffeescripts/locations.coffee";
import "../src/coffeescripts/questionnaires.coffee";
import "../src/coffeescripts/reviews.coffee";
import "../src/coffeescripts/schedule_times.coffee";
import "../src/coffeescripts/resource_reports.coffee";
import "../src/coffeescripts/lay_users.coffee";
import "../src/coffeescripts/job_postings.coffee";
import "../src/coffeescripts/unions.coffee";
import "../src/coffeescripts/ms_associate.coffee";
import "../src/coffeescripts/auxano_plans.coffee";
import "../src/coffeescripts/appointments.coffee";
import "../src/coffeescripts/conferences.coffee";
import "../src/coffeescripts/admin_reports.coffee";
import "../src/coffeescripts/evaluation_summaries.coffee";

// ActionCable Channels
import "../channels/notification.coffee";
import "../channels/web_notifications.coffee";
// import '../channels/real_time_changes'

// CSS Module Imports
// import "@fortawesome/fontawesome-free/css/all.css";
// import "jquery-ui/dist/themes/base/jquery-ui.min.css";

$(function () {
  $('[data-toggle="tooltip"]').tooltip();

  $(".datepicker").datepicker({
    dateFormat: "dd M yy",
  });

  $("form#custom-resources").on("keypress", function (e) {
    if (e.keyCode == 13) {
      return false;
    }
  });

  // close notification dropdown when click outside of notification
  $(".notification.px-4").bind("clickoutside", function () {
    var currentElement = $("a.notification-bell-icon");
    var parent = currentElement.closest(".dropdown");
    currentElement.attr("aria-expanded", false);
    parent.removeClass("show");
    parent.find("ul.dropdown-menu").removeClass("show");
  });

  $(".update.px-4").bind("clickoutside", function () {
    var currentElement = $("a.update-bell-icon");
    var parent = currentElement.closest(".dropdown");
    currentElement.attr("aria-expanded", false);
    parent.removeClass("show");
    parent.find("ul.dropdown-menu").removeClass("show");
  });
});

$(document).on("click", ".custom-submit-loader", function (e) {
  e.preventDefault();
  $("#loader-cont").show();
  let form = $(this).closest("form");
  if (form.attr("data-remote")) {
    Rails.fire(form[0], "submit");
  } else {
    form.submit();
  }
});

$(document).on("ajax:success", function () {
  $("#loader-cont").hide();
});
