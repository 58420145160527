$(->
  $(".auxano-sortable").sortable(
    cursor: "move",
    update: (e, ui) ->
      Rails.ajax(
        url: $(this).data('url'),
        type: 'PUT',
        data: $(this).sortable('serialize')
      )
  );

  $('.activity-season, .activity-status').change ->
    obj = {}
    if $(this).val().length > 0
      obj[$(this).attr('id')] = $(this).val();
      $.ajax(
        url: $(this).data('url'),
        type: 'PUT',
        dataType: 'script',
        data: obj
      )
)
$(document).on 'click', '.activity-season', ->
  obj = {}
  obj['season_id'] = $(this).data('value');
  $.ajax(
    url: $(this).data('url'),
    type: 'PUT',
    dataType: 'script',
    data: obj
  )

# activity rating modal form
$(->
  $('.activity-rating-modal-form').each( (indx, elem) ->
    $(elem).validate({
      rules: {
        "comment": {
          required: false
        }
      },
      submitHandler: () => $('#loader-cont').show()
    });
  )
)
$(document).on 'click', '.add-review-activity-auxano', ->
  form = $(this).closest('form')
  ratingform = form.find(".resource_review_rating")
  if (ratingform.attr('aria-required') == 'true' && ratingform.val() == '0')
    $(".validate_review_message").removeClass("hide")
    event.preventDefault();
  else
    $(".validate_review_message").addClass("hide")
    Rails.fire(form[0], 'submit')

$(document).on 'change', '.activity-modal-block select.activity-status', ->
  parent = $(this).closest('.activity-modal-block')
  if ['completed', 'discontinued'].includes($(this).val())
    parent.find('.rate-review-list').removeClass('hide-and-disable')
  else
    parent.find('.rate-review-list').addClass('hide-and-disable')