$(->
  $('.questionnaire-row td:not(:last-child)').click(->
    location.href = $(this).closest('tr').attr('data-href')
  )

  # ===== Questionnaire listing switch tabs, change tab parameter value =====
  $('#questionnaires-li').click(->
    $('#questionnaire-listing-cont .form-row #tab').val('questionnaires');
  )
  $('#archived-li').click(->
    $('#questionnaire-listing-cont .form-row #tab').val('archived');
  )
)

# ==== reorder question number in adding new questionnaire in form ======
$(document).on('click', '.add_quest_next_btn_div', ->
  parent = $(this).parent()
  totalQuestions = parent.find('.nested-fields')

  totalQuestions.each( (indx, element) ->
    $(element).find('label').text('Question '+(indx+1))
  )
)

# ==== Alert Publish status =====
$(document).on 'click', '#publish-review-template', (e) ->
  e.preventDefault()
  $('#publish-alert-modal').modal('show')
  return false;


$(document).on 'click', '.template-publish-confirmation', ->
  $('.review-template-status').val('published')
  $('form.review-template-form').submit()


# ==== ToggleClass ====
$(document).on 'click', '.quest_view_sidebar_list_item', ->
  appendActiveTabClass($(this))
  return false

# ======== Appending select competency parameter runtime =======
$(document).on 'click', '.add-current-competency-urlstate', ->
  pushStateSelectedCompetency($(this))
  return false



pushStateSelectedCompetency = (current_element) ->
  [url, queryString] = location.href.split('?')
  newQueryString = if(queryString) then queryString.split('&').filter((elem) -> !elem.includes('selected_competency')).join('&') else '?selected_competency='+current_element.data('url-state-change')
  if(newQueryString.length == 0)
    newQueryString = '?selected_competency='+current_element.data('url-state-change')
  else if(!newQueryString.includes('?'))
    newQueryString = '?'+newQueryString+'&selected_competency='+current_element.data('url-state-change')
  # queryString = if (queryString) then "?"+queryString + '&selected_competency='+current_element.data('url-state-change') else '?selected_competency='+current_element.data('url-state-change')
  new_url = url+newQueryString
  history.pushState({}, '', new_url)
  return false;

appendActiveTabClass = (current_element) ->
  parentUl = current_element.parent();
  parentUl.find('li').each( (indx, element) ->
    $(element).removeClass('active-tab')
  )
  current_element.addClass('active-tab')
  return false