// 2B removal function is custom one, not cocoon
$(document).on('click', ".close-foreground-row", function () {
  form = $(this).closest('form');
  updateMinistryReport2(form);
  $(this).closest('tr').remove();
});

// when cocoon removes row from mr
$(document).on('cocoon:after-remove', '#main-report-cont', function (event, target) {
  form = target.closest('form');
  updateMinistryReport2(form);
})

// when cocoon adds row from mr
$(document).on('cocoon:after-insert', '#main-report-cont', function (event, target) {
  form = target.closest('form');
  updateMinistryReport2(form);
})


var updateMinistryReport2 = function (form) {
  var required_accordion = form.find('.collapse.show').attr('id');
  form.attr('action', form.attr('action') + '?accordion_open=' + required_accordion+'&re_render=true');
  form.css({'opacity': 0.4})
  $.ajax({
    url: form.attr('action'),
    type: "POST",
    data: form.serialize(),
    dataType: 'script',
    beforeSend: function (xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    complete: function () {
      setTimeout((function () {
        form.css({'opacity': 1})
      }), 1000);
    }
  });
};