ministers_for_job_notification = (klass) ->
  $.each ['.conference-list-notification', '.notification-location-type-list', '.users-list-notification-cont'], (index, elem) ->
    $(elem).multipleSelect filter: true, placeholder: 'Select', formatAllSelected: ->
      return 'All ' + $( elem + ' option').length + ' selected'

  $(document).on 'change', 'select.selectable_list', ->
    conf_ids = $(this).closest('.modal-body').find('.conference-list-notification').val()
    location_type_ids = $(this).closest('.modal-body').find('.notification-location-type-list').val()
    $.ajax(
      url: '/notifications/fetch_required_ministers'
      type: 'GET'
      dataType: 'JSON'
      data:
        'conference_ids': conf_ids
        'location_type_ids': location_type_ids
        'conference_ministers': true).done (ministers) ->
      options = ''
      $(ministers).each (indx, elem) ->
        name = elem.first_name + ' ' + elem.last_name
        if name.length == 1
          name = elem.email
        if elem.main_role != null
          role = "("+elem.main_role.toUpperCase()+")"
        options = options + "<option value='" + elem.id + "'>"+ name + ' ' + role + "</option>"
        return
      $('select' + klass).html options
      $('select' + klass).multipleSelect 'destroy'
      $('select' + klass).multipleSelect filter: true, placeholder: 'Select'
      return
    return
  return

window.ministers_for_job_notification = ministers_for_job_notification
