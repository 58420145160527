gstart_time = '';
gend_time = '';
$(->
  $('#calendar').fullCalendar(
    height: 650
    header: {
              left:   '',
              center: 'prev title next',
              right:  'today, agendaWeek, month'
            }
    columnHeaderFormat: 'MMM D - ddd'
    defaultView: 'agendaWeek'
    events: '/schedule_times'
    displayEventTime: true
    selectable: true
    displayEventEnd: true
    timeFormat: 'h:mm a'
    slotDuration: '00:15:00'
    slotLabelInterval: '00:15'
    businessHours: {
      dow: [ 1, 2, 3, 4, 5, 6, 7 ]
      start: '08:00'
      end: '18:00'
    }
    minTime: '08:00:00'
    maxTime: '18:00:00'
    selectOverlap: (event) ->
      # if event preset then it return false so user not able to select date which have events.
      return !event;

    # === on clicking a cell ===
    select: (start, end, jsEvent, view) ->
      if $('.interview-availability-container.empty-slots-cont').length > 0
        availEmptySlot(start, end)
      return false
    eventClick: (event, jsEvent, view) ->
      if $('.interview-availability-container.empty-slots-cont').length > 0
        if event.status != 'closed'
          $.ajax(
            url: '/schedule_times/'+event.id,
            dataType: 'script',
            type: 'GET'
          )
      else
        # when MIN clicks schedule time
        eventEndDate = new Date(moment(event.end).format('MMMM D, YYYY H:mm:ss'))
        currentDate = new Date();
        if eventEndDate > currentDate && event.status != 'closed'
          # check already occupied by another MIN
          if(event.occupied)
            $.ajax(
              url: '/schedule_times/'+event.id,
              dataType: 'script',
              type: 'GET'
            )
          else
            $.ajax(
              url: '/schedule_times/'+event.id+'/appointments/confirmation'
              dataType: 'script'
              type: 'GET'
            )
      return false
    eventRender: (event, element) ->
      if(event.occupied)
        $.ajax(
          url: '/schedule_times/'+event.id+'/check_current_user_as_interviewer',
          dataType: 'json',
          type: 'GET',
          success: (data, textStatus, jqXHR) ->
            if(data == true)
              $(element).css
                "background": "url('assets/green-stripe.png') right"
                "border-color": "#74da92"
            else
              $(element).css
                "background-color": "#ffaeae !important"
                "border-color": "red"
        )
        $(element).css
          "background": "url('assets/green-stripe.png') right"
          "border-color": "#74da92"
  )
)

#==== hide/show schedule form in Popup
$(document).on 'click', '.schedule-edit-fa', ->
  $(this).toggleClass('hide')
  $(this).siblings('.schedule-view-fa').toggleClass('hide')
  parent = $(this).closest('.modal-content')
  parent.find('.modal-body #schedule-event-detail-cont').toggleClass('hide')
  parent.find('.modal-body #schedule-event-form-cont').toggleClass('hide')
  return false
$(document).on 'click', '.schedule-view-fa', ->
  $(this).toggleClass('hide')
  $(this).siblings('.schedule-edit-fa').toggleClass('hide')
  parent = $(this).closest('.modal-content')
  parent.find('.modal-body #schedule-event-detail-cont').toggleClass('hide')
  parent.find('.modal-body #schedule-event-form-cont').toggleClass('hide')
  return false

#==== Avail empty slot
$(document).on('click', '#yes-available-btn', ->
  obj = { schedule_time: { start_time: gstart_time, end_time: gend_time } }
  $.ajax(
    url: '/schedule_times',
    dataType: 'script',
    data: obj,
    type: 'POST',
    beforeSend: (xhr) ->
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content') )
  )
)

#==== Confirmation Popup before deleting schedule time
$(document).on 'click', '.schedule-delete-fa', ->
  $('#schedule-event-detail-modal').modal('hide')
  $('.delete-schedule-event-modal').modal()
#==== Delete available slot
$(document).on 'click', '#yes-delete-schedule-time-btn', ->
  event_id = $('#schedule_event_id_field').val()
  $.ajax(
    url: '/schedule_times/'+event_id,
    dataType: 'script',
    type: 'DELETE',
    beforeSend: (xhr) ->
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content') )
  )
  return false

availEmptySlot = (start, end) ->
  start_date = start.format("H")
  start_date_string = start_date.toString()
  if ((start_date_string >= 8 && end.format("HH") < 18) || (start_date_string >= 8 && end.format("HH") == '18' && end.format("m") == '0'))
    start = moment(start)
    end = moment(end)
    schedule_modal = $('#schedule-interview-modal')
    start_span = schedule_modal.find('.start-time-span')
    end_span = schedule_modal.find('.end-time-span')
    # console.log(start.format("MM/DD/YYYY h:mm:ss a"), end.format("MM/DD/YYYY h:mm:ss a"))
    gstart_time = start.format("YYYY-MM-DD h:mm:ss a")
    gend_time = end.format("YYYY-MM-DD h:mm:ss a")
    start_span.html(start.format("h:mm a"))
    end_span.html(end.format("h:mm a"))
    schedule_modal.modal()
  else
    alert("You can only book the interview slot from 08:00am to 6:00pm");
  return false
