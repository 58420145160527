$(document).ready ->
  $('.conference_min_add_form').validate(
    rules: 'start_date': required: true, 'report_to': required: true
  )
  return

$(document).on 'click', '.add-existing-user-conference, .add-existing-user-location', ->
  if ($(this).closest('form').valid())
    $(this).attr('disabled', true)
    $('#loader-cont').show()
    Rails.fire($(this).closest('form')[0], 'submit')

$(document).on 'click', '.conference-ministry-tabs', ->
  newurl = updateQueryStringParameter('active-tab', $(this).data('active-tab'))
  window.location.href = newurl
  return
# select with or without conference ministers, and add it to his conference by MS
$(document).on 'click', '.add_request_user_to_conference', ->
  url = $(this).data('href') + '?minister_id=' + $('.transfer-in-ministers-dropdown option:selected').val()
  $('#transfer-in-modal').modal('hide');
  $.ajax
    method: 'GET'
    url: url
    dataType: 'script'
  return

$(document).on 'click', '.popsup-confirmation-add-user-request', (e) ->
  e.preventDefault();
  url = $(this).data('url');
  $.ajax
    method: 'GET'
    url: url
    dataType: 'script'
  return

updateQueryStringParameter = (key, value) ->
  uri = window.location.href
  re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  separator = if uri.indexOf('?') != -1 then '&' else '?'
  if uri.match(re)
    uri.replace re, '$1' + key + '=' + value + '$2'
  else
    uri + separator + key + '=' + value
