$(document).ready ->
  $('body').on 'change', '.intern_location_id', ->
    location_id = $(this).val()
    $.ajax(
      url: '/internships/intern_invites/fetch_supervisors'
      type: 'GET'
      dataType: 'JSON'
      data: 'location_id': location_id).done (supervisors) ->
      options = ''
      $(supervisors).each (indx, elem) ->
        name = elem.first_name + ' ' + elem.last_name
        if name.length == 1
          name = elem.email
        options = options + '<option value=\'' + elem.id + '\'>' + name + '</option>'
        return
      $('select#supervisor_id').html options
      if options.length == 0
        $("#invite_intern_form .invite-error-box, #change_to_intern_form .invite-error-box").html("<p class='text-uppercase mb-0'> Selected location has no supervisor or supervisors are already associated with interns. </p>")
      else
        $("#invite_intern_form .invite-error-box, #change_to_intern_form .invite-error-box").html('');
      return
    return

  $('body').on 'submit', '#invite_intern_form', (e) ->
    if $(this).valid()
      $('#loader-cont').show()
      return

  preventNonNumericalInput = (e) ->
    e = e or window.event
    charCode = if typeof e.which == 'undefined' then e.keyCode else e.which
    charStr = String.fromCharCode(charCode)
    if !charStr.match(/^[0-9]+$/)
      e.preventDefault()
    return

  window.preventNonNumericalInput = preventNonNumericalInput

  $(".associate-ministers-dropdown").select2({
    placeholder: "Search existing associate ministers",
    theme: "bootstrap"
  });

  # modal popup for minister to intern conversion
  $(document).on 'click', '.intern-form-link', (e) ->
    e.preventDefault()
    selected_minister_id = $('.existing-mins').val();
    if(selected_minister_id.length == 0)
      toastr.error('Select a existing minister.')
    else
      Rails.fire($(this).closest('form')[0], 'submit')