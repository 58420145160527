$(document).on 'click', '.focus_area_explanation_toggle', ->
  $(this).closest('.focus-area-block').find('.focus_area_explanation_slider').slideToggle();
# ====== AUXANO accordion when open, change query parameter
$(document).on 'click', '.auxano-card-cont', ->
  if !$(this).find('.auxano-accordion-bar').hasClass('collapsed')
    year = $(this).data('year')
    loc_hash = $(this).attr('id')
    [url, quryString] = location.href.split('?')
    new_url = url+'?year='+year+'#'+loc_hash
    history.pushState({}, '',new_url)
