$(document).ready ->
  $('.ministers-dropdown').select2
    placeholder: 'Add existing minister'
    theme: 'bootstrap'
  $('body').on 'click', '.add_min_btn', ->
    url = $(this).data('href') + '?minister_id=' + $('.ministers-dropdown option:selected').val()
    $.ajax
      method: 'GET'
      url: url
      dataType: 'script'

$(document).on 'click', '.r-season-cont', ->
  location.href = $(this).data('url')
