$(->
  renderPreachingSummaryForm()
)

# ============= Events ===============
$(document).on 'click', '.preaching-summary-link', ->
  toggleActiveTabClass($(this))
  renderPreachingSummaryForm()

$(document).on 'click', '.conference-review-link', ->
  toggleActiveTabClass($(this))
  renderConferenceReviewForm()

$(document).on 'click', '.track-attendance-link', ->
  toggleActiveTabClass($(this))
  rendertrackAttendanceForm()

$(document).on 'click', '.profile-statistics-link', ->
  toggleActiveTabClass($(this))
  renderProfileStatisticsForm()


# ============== Functions =============
renderPreachingSummaryForm = ->
  target = $('.preaching-summary-block')
  if(target.length == 0)
    return false
  toggleLoaderBaseClasses()
  $.ajax(
    url: 'preaching_summary/new',
    type: 'GET'
  ).done((data)->
    target.html(data)
  )

renderConferenceReviewForm = ->
  target = $('.preaching-summary-block')
  toggleLoaderBaseClasses()
  $.ajax(
    url: 'conference_review/new',
    type: 'GET'
  ).done((data) ->
    target.html(data)
    $('#loader-box').hide()
  )

rendertrackAttendanceForm = ->
  target = $('.preaching-summary-block')
  toggleLoaderBaseClasses()
  $.ajax(
    url: 'average_track_attendance',
    type: 'GET'
  ).done((data) ->
    target.html(data)
  )

renderProfileStatisticsForm = ->
  target = $('.preaching-summary-block')
  toggleLoaderBaseClasses()
  $.ajax(
    url: 'profile_statistics_form',
    type: 'GET'
  ).done((data) ->
    target.html(data)
  )

# Toggle active tab class on switch tabs
toggleActiveTabClass = (target_element) ->
  $('.list-inline-item a').each( (index, elem) ->
    $(elem).removeClass('active')
  )
  if(!target_element.hasClass('active'))
    target_element.addClass('active')

# Toggle loader on switching tabs
toggleLoaderBaseClasses = ->
  classes = ['#conference-review-cont', '#track-attendance-cont', '#preaching-summary-cont']
  classes.forEach( (elem) ->
    if($(elem).length > 0)
      $('#loader-box').show()
      $(elem).hide()
  )
