$(->
  $('.back-form-button').click ->
    render_step1()
    $('.user-first-name').focus()
    return
  $('.next-form-button').click (e) ->
    # e.preventDefault();
    if $('.custom-form-validation').valid()
      render_step2()
      $('#user_mentor').focus();
      return
  if $('.mentor-checkbox').hasClass('edit-mentor')
    toggle_mentor_detail($('.mentor-checkbox'))
)
#===== mentor detail =======

$(document).on 'click', '.mentor-checkbox input[type=checkbox]', ->
  toggle_mentor_detail($('.mentor-checkbox'))

#============== Functions ==============
toggle_mentor_detail = (mentor_checkbox) ->
  is_mentor = mentor_checkbox.find('input[type=checkbox]').is(':checked')
  mentor_detail = $('.mentor-detail')
  if is_mentor
    mentor_detail.removeClass('hide-and-disable')
    mentor_detail.find('input, textarea').each(->
      $(this).removeAttr('disabled')
    )
  else
    mentor_detail.addClass('hide-and-disable')
    mentor_detail.find('input, textarea').each(->
      $(this).attr('disabled', true)
    )

# Sign up file upload
$(document).on 'change', '#uploadFile', ->
  handleFiles(this)
  $('#uploadTrigger').removeClass('error')
  $('#uploadTrigger-error').addClass('visibility-hidden')
  $('#uploadTrigger-error').removeClass('error')
  false

$(document).on 'click', '#uploadTrigger', ->
  $(this).closest('.user-image-div').find('#uploadFile').trigger 'click'
  false

#======= Profile Preferences Link =========
# $(document). on 'click', '.profile-preferences-link', (e) ->
#   e.preventDefault()
#   if( $(".save-continue-profile-form").length > 0 )
#     $('.save-continue-profile-form').click()
#   else
#     location.href = $(this).attr('href')
#   return false

$(document). on 'change', '.upload-resume-file', (e) ->
  $('.form-submit-button').click()
  return false


#======== Dynamic avatar change =======
handleFiles = (fileInput) ->
  files = fileInput.files
  i = 0
  while i < files.length
    file = files[i]
    imageType = /image.*/
    if !file.type.match(imageType)
      i++
      continue
    img = document.createElement('img')
    img.classList.add 'user-icon'
    img.classList.add 'avatar-selected'
    img.file = file
    $('.user-image').html img
    reader = new FileReader
    reader.onload = ((aImg) ->
      (e) ->
        aImg.src = e.target.result
        return
    )(img)
    reader.readAsDataURL file
    $(fileInput).siblings('#uploadTrigger').removeClass('error')
    i++
  return

render_step2 = ->
  $('.next-form-button').hide()
  $('.step-1').hide()
  $('.step-2').show()
  $('.form-1').hide()
  $('.form-2').show()
  $('.form-signin').show()
  $('.mentor-checkbox').show()
  $('.back-form-button').show()
  toggle_mentor_detail($('.mentor-checkbox'))
  return

render_step1 = ->
  $('.back-form-button').hide()
  $('.step-2').hide()
  $('.step-1').show()
  $('.form-2').hide()
  $('.form-1').show()
  $('.form-signin').hide()
  $('.mentor-checkbox').hide()
  $('.next-form-button').show()
  return

# upload pdf file
$(document).on 'change', '#uploadPDFFile', ->
  $(".pdf_downloadable_link").hide();
  $(".uploaded-pdf-name").removeClass('hide');
  $(".uploaded-pdf-name").text(this.files[0].name);
  $('#uploadPDFTrigger').removeClass('error')
  $('#uploadPDFTrigger-error').addClass('visibility-hidden')
  $('#uploadPDFTrigger-error').removeClass('error')
  false

$(document).on 'click', '#uploadPDFTrigger', ->
  $(this).closest('.user-image-div').find('#uploadPDFFile').trigger 'click'
  false
