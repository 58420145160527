$(->
  $('.msa-ministers-dropdown').select2(
    placeholder: 'Add existing minister',
    theme: 'bootstrap',
    width: '220px'
  )
)

# ==== Popup modal for assign MIN to MSA
$(document).on 'click', '.assign-existing-min-msa', ->
  parent = $(this).closest('.invite-assign-min-container')
  selected_minister_id = parent.find('.msa-ministers-dropdown').val();
  $.ajax(
    url: $(this).data('href')+'?selected_minister_id='+selected_minister_id
    dataType: 'script'
  )
  return false
