$(function() {
  indexing_profile_children();
});

$(document).on('nested:fieldAdded', function(event){
  // this field was just inserted into your form
  var field = event.field;
  // it's a jQuery object already! Now you can find date input
  var dateField = field.find('.datepicker1');
  // and activate datepicker on it
  dateField.datepicker({
    changeYear: true,
    dateFormat: "dd/mm/yy",
    yearRange: "-50:+0"
  });
});

$(document).on('click', '.add-profile-children, .remove-profile-children', () => {
  indexing_profile_children();
});

// Account Setting Profile section, index children module as child 1, 2....
const indexing_profile_children = () => {
  const parent = $('.profile-children');
  parent.find('.fields:not([style*="display: none"]) .child-number').each((indx, elem) => {
    $(elem).html(`child ${indx + 1}`);
  });
}
$(document).ready(function(){
  $('.user-profile-search-filters select').on('change', function(){
    if($(this).find('option:selected').index() != 0) {
      $(this).addClass('custom-select');
    }else {
      $(this).removeClass('custom-select');
    }
  });
});

$(document).ready(function() {
  // Function to toggle the sort icon
  function toggleSortIcon(linkId, ulId) {
    var $link = $(`#${linkId}`);
    if (ulId) { var $ul = $(`#${ulId}`); }
    var $sortIcon = $link.find('i');

    if ($ul && $ul.hasClass('show')) {
      $sortIcon.removeClass('fa-sort-down').addClass('fa-sort-up');
    } else {
      $sortIcon.removeClass('fa-sort-up').addClass('fa-sort-down');
    }
  }

  // Initial check when document is ready
  toggleSortIcon('ministry-link', 'ministry-ul');
  toggleSortIcon('development-link', 'development-ul');
  toggleSortIcon('configure-link', 'configure-ul');
  toggleSortIcon('information-link', 'information-ul');
  toggleSortIcon('coworker-catalogue-link', 'coworker-catalogue-ul');

  // Toggle the sort icon when link is clicked
  $('.nav-link').click(function() {
    var linkId = $(this).attr('id');
    var ulId = $(this).attr('href').substring(1); // Remove '#' from href
    toggleSortIcon(linkId, ulId);
  });

  // Toggle the sort icon when 'show' class is toggled on ul
  $('ul.collapse').on('shown.bs.collapse hidden.bs.collapse', function() {
    var ulId = $(this).attr('id');
    var linkId = ulId.replace('-ul', '-link');
    toggleSortIcon(linkId, ulId);
  });
});