# Execute on MR create btn
$(document).on 'click', '.create-mr-btn', (e)->
  e.preventDefault()
  $('#loader-cont').show()
  form = $(this).closest('form')
  Rails.fire(form[0], 'submit')

$(document).on 'click', '.mn-report-tr', ->
  window.location = $(this).parent().attr('data-href')
  return false
$(document).on 'touchend', '.mn-report-tr', ->
  window.location = $(this).parent().attr('data-href')
  return false
# ===== 1a save btns action =====
$(document).on 'click', '.report-1a-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('.mntry-report-1a-cont').find('form.mn-report-form'))

# ===== 2a submit btns action =====
$(document).on 'click', '.accordion-print-btn', (e) ->
  e.stopPropagation()
$(document).on 'click', '.report-2a-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_2a_cont').find('form.2a-report-form'))
  # save id of accordion which is currently active
  # required_accordion = $('#report_2a_cont').find('.collapse.show').attr('id')
  # $('.2a-report-form').attr('action', $('.2a-report-form').attr('action')+'?accordion_open='+required_accordion )
  # Rails.fire($('.2a-report-form')[0], 'submit');

$(document).on 'click', 'a[data-hash-tag="#report-1a"],
                         a[data-hash-tag="#report-2a"],
                         a[data-hash-tag="#report-3a"],
                         a[data-hash-tag="#report-1b"],
                         a[data-hash-tag="#report-2b"],
                         a[data-hash-tag="#report-3b"],
                         a[data-hash-tag="#report-4a"],
                         a[data-hash-tag="#report-4b"],
                         a[data-hash-tag="#report-5"],
                         a[data-hash-tag="#report-6a"],
                         a[data-hash-tag="#report-comments"],
                         a[data-hash-tag="#report-admins-comments"]', (e) ->
  # on 5 add class else remove
  if ($(this).attr('data-tag-id') == '#5_title')
    $('.min_rpt_1a_upr_div').addClass('min_class_5_upr_div')
  # else if ($(this).attr('data-tag-id') == '#b_1_title' || $(this).attr('data-tag-id') == '#a_1_title')
  else
    $('.min_rpt_1a_upr_div').removeClass('min_class_5_upr_div')
  
  if $('.min_rpt_1a_upr_div').hasClass('hide')
    $('.min_rpt_1a_upr_div').removeClass('hide')


$(document).on 'keyup', '.1b-aditional-notes', (e) ->
  e.preventDefault()
  e.stopPropagation()
  obj = {}
  element = $( "textarea[name*='report_snapshot[detail][funding_application]']" )
  value = $(element).val()
  url = $(element).attr('href')
  obj["funding_application"] = value
  $.ajax(
    url: url,
    type: 'PUT',
    dataType: 'script',
    data: obj
  )

# ===== 2b submit btns action =====
$(document).on 'click', '.report-2b-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_2b_cont').find('form.report-2b-form'))
  # save id of accordion which is currently active
  # required_accordion = $('#report_2b_cont').find('.collapse.show').attr('id')
  # $('.report-2b-form').attr('action', $('.report-2b-form').attr('action')+'?accordion_open='+required_accordion )
  # Rails.fire($('.report-2b-form')[0], 'submit');

# ===== 7 submit btns action =====
$(document).on 'click', '.report-7-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_7_cont').find('form.report-7-form'))

# ===== 3a submit btns action =====
$(document).on 'click', '.report-3a-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_3a_cont').find('form.report-3a-form'))
  # save id of accordion which is currently active
  # required_accordion = $('#report_3a_cont').find('.collapse.show').attr('id')
  # $('.report-3a-form').attr('action', $('.report-3a-form').attr('action')+'?accordion_open='+required_accordion )
  # Rails.fire($('.report-3a-form')[0], 'submit');

# ===== 3b submit btns action =====
$(document).on 'click', '.report-3b-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_3b_cont').find('form.report-3b-form'))
  # save id of accordion which is currently active
  # required_accordion = $('#report_3b_cont').find('.collapse.show').attr('id')
  # $('.report-3b-form').attr('action', $('.report-3b-form').attr('action')+'?accordion_open='+required_accordion )
  # Rails.fire($('.report-3b-form')[0], 'submit');

$(document).on 'click', '.report-6a-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_6a_cont').find('form.report-6a-form'))

# ===== 5 submit btns action =====
$(document).on 'click', '.report-5-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('#report_5_cont').find('form.report-5-form'))
  
$(document).on 'click', '.report-4a-submit-btn', (e) ->
  e.preventDefault()
  updateMinistryReport($('form.report-4a-form'))

$(document).on 'click', 'a[data-hash-tag="#report-1a"],
                         a[data-hash-tag="#report-2a"],
                         a[data-hash-tag="#report-3a"],
                         a[data-hash-tag="#report-1b"],
                         a[data-hash-tag="#report-2b"],
                         a[data-hash-tag="#report-3b"],
                         a[data-hash-tag="#report-4a"],
                         a[data-hash-tag="#report-4b"],
                         a[data-hash-tag="#report-5"],
                         a[data-hash-tag="#report-6a"],
                         a[data-hash-tag="#report-7"],
                         a[data-hash-tag="#report-comments"],
                         a[data-hash-tag="#report-admins-comments"]', (e)->
  e.preventDefault()
  current_element = $(this)
  parent = $(this).closest('#1a-1b-ul')

  # -- remove tabs active class and insert to current element
  parent.find('li > a').each(->
    $(this).removeClass('active')
  )
  current_element.addClass('active')

  # -- hide siblings title and show current element title
  $(current_element.attr('data-tag-id')).parent().find('.report-title-paragraph').each(->
    if !$(this).hasClass('hide')
      $(this).addClass('hide')
  )
  if (current_element.attr('data-tag-id') == '#b_1_title')
    $(current_element.attr('data-tag-id')).closest('#mnstry-report-1a-cont')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1a-logo-cont').addClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-5-logo-cont').addClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1b-logo-cont').removeClass('hide')

  else if (current_element.attr('data-tag-id') == '#a_1_title')
    $(current_element.attr('data-tag-id')).closest('#mnstry-report-1a-cont')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1a-logo-cont').removeClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1b-logo-cont').addClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-5-logo-cont').addClass('hide')

  
  else if (current_element.attr('data-tag-id') == '#5_title')
    $(current_element.attr('data-tag-id')).closest('#mnstry-report-1a-cont')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1a-logo-cont').addClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-1b-logo-cont').addClass('hide')
    $(current_element.attr('data-tag-id')).closest('.upr-section-1a-1b').find('.report-5-logo-cont').removeClass('hide')

  $(current_element.attr('data-tag-id')).removeClass('hide')

  # -- hide siblings report and show current element report
  $('.report_review_1a_cont, .report_review_2a_cont,
     .report_review_3a_cont,
     .report_review_1b_cont, .report_review_2b_cont,
     .report_review_3b_cont, .report_review_4a_cont,
     .report_review_4b_cont, .report_review_5_cont, .report_review_6a_cont, .report_review_7_cont ,#report_comments_cont, #report_admins_comments_cont').each(->
    if !$(this).hasClass('hide')
      $(this).addClass('hide')
  )

  #-- hide and show required action btns
  $('.report-action-btns').children().not(current_element.attr('data-report-action')).each(->
    $(this).addClass('hide')
  )
  $(current_element.attr('data-report-action')).each(-> $(this).removeClass('hide'))
  $(current_element.attr('data-report-cont-id')).removeClass('hide')

  #-- toggle seas green upper div
  if ['2a-link-tab', '2b-link-tab', '3a-link-tab', '3b-link-tab', '4a-link-tab', '4b-link-tab', '6a-link-tab','7-link-tab', 'comments-link-tab', 'admins-comments-link-tab'].indexOf(current_element.attr('id')) > -1
    if $("#comments-scrollable-div").length > 0
      $("#comments-scrollable-div").scrollTop($("#comments-scrollable-div")[0].scrollHeight);
    $(current_element.attr('data-report-seagreen-box')).addClass('hide')
  else
    $(current_element.attr('data-report-seagreen-box')).removeClass('hide')

  close_all_accordion()
  return false

# === submit report ====
$(document).on 'click', '#report-submit-btn', ->
  $('#submit-report-trigger')[0].click()
  return

mr_disabled = false;
$(document).ready ->
  if( $('#disabled-mr-fields').val() != undefined )
    mr_disabled = if($('#disabled-mr-fields').val().length == 0 || $('#disabled-mr-fields').val() == 'false') then false else true;
  if location.hash
    # $('a'+location.hash).trigger 'click'
    $('a[data-hash-tag=\'' + location.hash + '\']').trigger 'click'
  else if $('a[data-hash-tag="#report-1a"]').length > 0
    $('a[data-hash-tag="#report-1a"]')[0].click();

  # to change url hash according to selected tab
  $(document.body).on 'click', 'a[data-hash-tag="#report-1a"],
                                a[data-hash-tag="#report-2a"],
                                a[data-hash-tag="#report-3a"],
                                a[data-hash-tag="#report-1b"],
                                a[data-hash-tag="#report-2b"],
                                a[data-hash-tag="#report-3b"],
                                a[data-hash-tag="#report-4a"],
                                a[data-hash-tag="#report-4b"],
                                a[data-hash-tag="#report-5"],
                                a[data-hash-tag="#report-6a"],
                                a[data-hash-tag="#report-7"],
                                a[data-hash-tag="#report-comments"],
                                a[data-hash-tag="#report-admins-comments"]', (event) ->
    location.hash = $(this).attr('data-hash-tag')

$(window).on 'popstate', ->
  anchor = location.hash or $("#1a-link-tab").attr('data-hash-tag')
  $('a[data-hash-tag=\'' + anchor + '\']').trigger 'click'


document.addEventListener("comments-scrolHeight-loaded", ->
  if location.hash
    current_element = $('a[data-hash-tag=\'' + location.hash + '\']');
    setTimeout (->
      if ['2a-link-tab', '2b-link-tab', '3a-link-tab', '3b-link-tab', '4a-link-tab', '4b-link-tab', '6a-link-tab', '7-link-tab', 'comments-link-tab', 'admins-comments-link-tab'].indexOf(current_element.attr('id')) > -1
        if $("#comments-scrollable-div").length > 0
          $("#comments-scrollable-div").scrollTop($("#comments-scrollable-div")[0].scrollHeight);
        $(current_element.attr('data-report-seagreen-box')).addClass('hide')
      else
        $(current_element.attr('data-report-seagreen-box')).removeClass('hide')
    ), 500
);
document.addEventListener("overall_health_loaded", ->
  # tooltip initializer for 'preview last season report' module
  $('[data-toggle="tooltip"]').tooltip()

  effectiveness_ratings()

  $('body').on 'keyup', '.score_fields', (e) ->
    minimum_factor =  parseFloat($(e.target).closest('tr').find(".score_fields:last")[0].value || 0)
    highest_factor = parseFloat($(e.target).closest('tr').find(".score_fields:first")[0].value || 0)
    difference = highest_factor - minimum_factor
    $(e.target).closest('tr').siblings("tr:last").find('td:last').text(difference)

  # ==== render rateyo for dynamic content addition
  $('.report_2a_card').on 'cocoon:after-insert', (event, insertedItem) ->
    rating_elem = $(insertedItem).find('.rating_review_modal_fields')
    rating_input = $(insertedItem).find('.av_rating')
    $(rating_elem).rateYo
      rating: 0.0
      fullStar: true
      starWidth: '25px'
      ratedFill: $(rating_input).data('color'),
      readOnly: mr_disabled
    $(rating_elem).click ->
      selected_value = $(this).rateYo('option', 'rating')
      $(this).closest('tr').find('.av_rating').val selected_value
);

document.addEventListener("discipleships-loaded", ->
  effectiveness_ratings()
   # ==== render rateyo for dynamic content addition
  $('.report_2a_card').on 'cocoon:after-insert', (event, insertedItem) ->
    rating_elem = $(insertedItem).find('.rating_review_modal_fields')
    rating_input = $(insertedItem).find('.av_rating')
    $(rating_elem).rateYo
      rating: 0.0
      fullStar: true
      starWidth: '25px'
      ratedFill: $(rating_input).data('color'),
      readOnly: mr_disabled
    $(rating_elem).click ->
      selected_value = $(this).rateYo('option', 'rating')
      $(this).closest('tr').find('.av_rating').val selected_value
);
document.addEventListener("discipleships-loaded2", ->
  $('[data-toggle="tooltip"]').tooltip()
  effectiveness_ratings()
  $(".primary_theme_btn_select").select2({
    placeholder: "Select Primary Theme",
    theme: "bootstrap",
    width: "100%",
    allowClear: true,
  });
   # ==== render rateyo for dynamic content addition
  $('.report_2a_card').on 'cocoon:after-insert', (event, insertedItem) ->
    rating_elem = $(insertedItem).find('.rating_review_modal_fields')
    rating_input = $(insertedItem).find('.av_rating')
    $(rating_elem).rateYo
      rating: 0.0
      fullStar: true
      starWidth: '25px'
      ratedFill: $(rating_input).data('color'),
      readOnly: mr_disabled
    $(rating_elem).click ->
      selected_value = $(this).rateYo('option', 'rating')
      $(this).closest('tr').find('.av_rating').val selected_value
);

# ============= 2B Smart tooltip
document.addEventListener("vision-goal-report", ->
  $('[data-toggle="tooltip"]').tooltip()
)

effectiveness_ratings = ->
  $('.church_ratings').each (index, element) ->
    rate_element = $(element).closest('td').find('.av_rating')
    rating = parseFloat($(rate_element).val())
    $(element).rateYo
      rating: rating
      fullStar: true
      starWidth: '25px'
      ratedFill: $(rate_element).data('color'),
      readOnly: mr_disabled

    $(element).click ->
      selected_value = $(this).rateYo('option', 'rating')
      $(this).closest('td').find('.av_rating').val selected_value

$(document).on 'click', '.funding_application_save_btn', (e)->
  e.preventDefault()
  e.stopPropagation()
  obj = {}
  element = $( "textarea[name*='report_snapshot[detail][funding_application]']" )
  value = $(element).val()
  url = $(element).attr('href')
  obj["funding_application"] = value
  $.ajax(
    url: url,
    type: 'PUT',
    dataType: 'script',
    data: obj
  )

# ===== Closing all accordion when tabs clicks 2A, 2B ....
close_all_accordion = ->
  headers = $('.card-header:not(.collapsed)')
  bodies = $('.mn-report-form .collapse.show')
  headers.each( (indx, elem) ->
    $(elem).addClass('.collapsed').attr('aria-expanded', false)
  )
  bodies.each( (indx, elem) ->
    $(elem).removeClass('show')
  )

# ===== Vision Goal school term select
$(document).on('click', '#edit-vision-goal .school-term, #new-vision-goal .school-term', ->
  current_element = $(this)
  $('#edit-vision-goal .school-term,
     #new-vision-goal .school-term').each( (indx, el)->
    if(!$(el).hasClass('disabled_season'))
      $(el).addClass('disabled_season')
  )
  $('#edit-vision-goal .school-term,
    #new-vision-goal .school-term').each( (indx, el)->
    if($(el).data('term') == current_element.data('term'))
      $(el).removeClass('disabled_season')
  )
  $('.vision-goal-terms').val(current_element.data('term'));
)

# ===== Vision Goal season select
$(document).on('click', '#edit-vision-goal .vision-season, #new-vision-goal .vision-season', ->
  current_element = $(this)
  $('#new-vision-goal .vision-season,
    #edit-vision-goal .vision-season').each( (indx, el)->
    if(!$(el).hasClass('disabled_season'))
      $(el).addClass('disabled_season')
  )
  $('#new-vision-goal .vision-season,
    #edit-vision-goal .vision-season').each( (indx, el)->
    if($(el).data('season') == current_element.data('season'))
      $(el).removeClass('disabled_season')
  )
  $('.vision-goal-season').val(current_element.data('season'));
)


# === create ministry report form ========
# $(document).on 'mouseover', '.mntry-report .seasons-listing .seasson-span img', ->
#   parent = $(this).closest('.seasons-listing')
#   parent.find('img').each( (indx, elem) ->
#     if(!$(elem).hasClass('opacity-0-3'))
#       $(elem).addClass('opacity-0-3')
#   )
#   $(this).removeClass('opacity-0-3').addClass('opacity-1')
$(document).on(
  mouseenter: ->
    disable_season($(this))
    $(this).removeClass('opacity-0-3').addClass('opacity-1')
  mouseleave: ->
    disable_season($(this))
  click: ->
    parent = $(this).closest('.seasons-listing')
    parent.find('img').each( (indx, elem) ->
      if($(elem).hasClass('opacity-1') && $(elem).hasClass('active') )
        $(elem).addClass('opacity-0-3').removeClass('opacity-1 active')
    )
    $(this).addClass('opacity-1 active').removeClass('opacity-0-3')
    parent.find('.report-season-id').val($(this).data('value'))

  , '.mntry-report .seasons-listing .seasson-span img'

)
$(document).on 'change', '.report-year', (e) ->
  selected_year = $(this).val()
  $.ajax(
    url: '/reports/new?last_year='+selected_year
    type: 'GET'
    contentType: "text/javascript"
    dataType: 'script'
  )
  return

$(document).on 'change', '#induction-resource-dropdown', (e) ->
  $.ajax(
    url: $(this).attr("href")
    dataType: 'script'
    type: 'GET',
    data: {'intent': $(this).val()}
  )
  return

disable_season = (current_element) ->
  parent = current_element.closest('.seasons-listing')
  parent.find('img').each( (indx, elem) ->
    if($(elem).hasClass('opacity-1') && !$(elem).hasClass('active') )
      $(elem).addClass('opacity-0-3').removeClass('opacity-1')
  )

#======= Auto save review form =============
timer = undefined

$(document).on "change", ".induction-template-form", ->
  triggerAutoSave(this)
  $('.autosave-textbox').html 'Saving...'


$(document).on "change", "#report_4a_cont input", ->
  # but input field should be belongs to sda Teaching Modal
  if !$(this).prop('name').includes('sda_teaching')
    triggerAutoSave(this)

$(document).on 'input', '.mntry-report-1a-cont textarea,
                         .mntry-report-1a-cont input,
                         .moderate-review-form,
                         #report_2a_cont textarea,
                         #report_2b_cont input, #report_2b_cont textarea,
                         #report_7_cont input,
                         #report_3a_cont input, #report_3a_cont textarea,
                         #report_3b_cont input, #report_3b_cont textarea,
                         #report_4a_cont input, #report_4a_cont textarea,
                         #report_4b_cont input, #report_4b_cont textarea,
                         #report_6a_cont input, #report_6a_cont textarea,
                         #report_5_cont_form input, #report_5_cont_form textarea', ->
  if !$(this).prop('name').includes('sda_teaching') && !$(this).prop('name').includes('rejection_reason')
    triggerAutoSave(this)

# work on mobile, tabs
$.browser = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
if ($.browser)
  $(document).on 'click', '.mntry-report-1a-cont input[type=radio]', ->
    autoSaveReportTimeout($(this).closest('form'))
    return

$(document).on 'click', '#report_2a_cont svg,
                         #report_3a_cont svg,
                         #report_3b_cont svg', ->
  if( !mr_disabled )
    # disable add more, remove button while autosaving
    $('.add-more-rep-field, .remove_fields').each( (indx, elm) ->
      $(elm).find('button').prop('disabled', true)
    )
    autoSaveReportTimeout($(this).closest('form'))
  return false

$(document).on 'change', '#report_3b_cont .primary_theme_btn_select', ->
  # disable add more, remove button while autosaving
  $('.add-more-rep-field, .remove_fields').each( (indx, elm) ->
    $(elm).find('button').prop('disabled', true)
  )
  autoSaveReportTimeout($(this).closest('form'))
  return false

$(document).on 'click', '.fetch-delegate-layusers-btn', (e) ->
  e.preventDefault()
  form = $(this).closest('form')
  render_async_url = form.find('#render_async_url').val()
  accordion_open = form.find('.collapse.show').attr('id')
  parent_target_id = form.find('#parent_target_id').val()
  $.ajax(
    url: $(this).data('url')
    dataType: 'script'
    data: {'render_async_url': render_async_url, 'accordion_open': accordion_open, 'parent_target_id': parent_target_id}
  )
  return

$(document).on 'click', '.remove-delegate-layuser-preaching-btn', (e) ->
  e.preventDefault()
  form = $(this).closest('form')
  render_async_url = form.find('#render_async_url').val()
  accordion_open = form.find('.collapse.show').attr('id')
  parent_target_id = form.find('#parent_target_id').val()
  $.ajax(
    url: $(this).data('url')
    type: 'delete'
    dataType: 'script'
    data: {'render_async_url': render_async_url, 'accordion_open': accordion_open, 'parent_target_id': parent_target_id}
    beforeSend: (xhr) ->
      xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
  )
  return

# this function only use case is to prevent accordion from toggling when
# import Preaching button click in 3b
$(document).on 'click', '.import-location-preaching', (e) ->
  e.stopPropagation();
  return
# this function only use case is to prevent accordion from toggling when
# clicking checkbox share in 2b
$(document).on 'click', '#report_2b_cont .label-for-share-2b,
                         #report_2b_cont input[type=checkbox],
                         #report_4a_cont .label-for-share-4a,
                         #report_4a_cont input[type=checkbox],
                         #report_4b_cont input[type=checkbox],
                         #report_4b_cont .label-for-share-4a', (e) ->
  e.stopPropagation();
  return

# Equipping section of MR

$(document).on 'click', '.eqp-report-tr', ->
  url = $(this).data('url')
  location.href = url
  return


# ===== Functions ======

triggerAutoSave = (this_ref) ->
  # MS, PGS, UMS, SUPV can edit Part 4 of supervisor's report. so they are permitted users
  permitted_user = $('#permitted_user').val()

  if( !mr_disabled || permitted_user == 'true' )
    # disable add more, remove button while autosaving
    $('.add-more-rep-field, .remove_fields,
       .delete-foreground-row .close-foreground-row-2').each( (indx, elm) ->
      $(elm).find('button').prop('disabled', true)
    )
    autoSaveReportTimeout($(this_ref).closest('form'))
  return

autoSaveReportTimeout = (form) ->
  clearTimeout timer
  timer = setTimeout((->
    updateMinistryReport(form)
    return
  ), 2000)
  return

updateMinistryReport = (form) ->
  required_accordion = form.find('.collapse.show').attr('id')
  # save id of accordion which is currently active
  form.attr('action', form.attr('action'))
  # form.css({'opacity': 0.4})
  # form.find('*').css({'cursor': 'none'})
  $.ajax
    url: form.attr('action')
    type: "POST"
    data: form.serialize()
    dataType: 'script'
    beforeSend: (xhr) ->
      xhr.setRequestHeader 'X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')
      $('#report-submit-btn').prop('disabled', true)
      $('.autosave-textbox').html 'Saving...'
      $('.auto-save-cont').css('display', 'flex')
      return
    complete: ->
      setTimeout (->
        $('.autosave-textbox').html 'Saved!'  
        $('#report-submit-btn').prop('disabled', false)
        $('.add-more-rep-field, .remove_fields,
           .delete-foreground-row').each( (indx, elm) ->
          $(elm).find('button').prop('disabled', false)
        )
        # form.css({'opacity': 1})
        # form.find('*').css({'cursor': 'default'})
        return
      ), 1000
      setTimeout (->
        $('.autosave-textbox').html '&nbsp;'
        $('.auto-save-cont').css('display', 'none')
        return
      ), 2000
      return
  return

import_delegated_preaching = (element) ->
  url = element.data('url')
  form = element.closest('form')
  render_async_url = form.find('#render_async_url').val()
  accordion_open = form.find('.collapse.show').attr('id')
  parent_target_id = form.find('#parent_target_id').val()
  $.ajax(
    url: url
    dataType: 'script'
    data: { 'render_async_url': render_async_url, 'accordion_open': accordion_open, 'parent_target_id': parent_target_id }
  )
  return

# preaching application/note move cursor below on enter press event
focus_below_field = (klass_name) ->
  $(document).on 'keypress', klass_name, (e) ->
    if ( e.which == 13 )
      e.preventDefault()

      next_tr = undefined
      if ($(this).closest('tr').next('input').length > 0)
        next_tr = $(this).closest('tr').next().next('tr')
      else
        next_tr = $(this).closest('tr').next('tr')

      if next_tr.length > 0
        field = next_tr.find(klass_name)
        fldLength= field.val().length;
        field.focus()
        field[0].setSelectionRange(fldLength, fldLength);
# apply on all preaching fields
preaching_field_move_cursor = () ->
  fields = ['.preaching-note', '.preaching-speaker', '.preaching-message'];
  for x of fields
    focus_below_field(fields[x])
# call override behaviour
preaching_field_move_cursor()

send_mr_to_principal = (confirmation_url, main_url) ->
  $.ajax(
    url: confirmation_url,
    dataType: 'script',
    data: {url: main_url},
    method: 'GET'
  )


# Attached import_delegated_preaching func in window object so it is available globally
window.import_delegated_preaching = import_delegated_preaching

window.send_mr_to_principal = send_mr_to_principal
