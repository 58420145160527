//
//
//
// Below code has also been duplicated to app/views/shared/scripts/_report_reminder.html.erb
// If you are making changes to this file, then please update above file too.
// Note: The duplicated code is a patch to serve Js without Webpacker.
// 
//
//

$(document).ready(function(){
  var text = $(".reminder_message-div").attr('reminder-text');
  if(text != null){
    $(".reminder_message-text").html(text)
  }
})
$(document).on("click", ".send-reminder-button", function(){
  $('#report-submission-reminder').modal("hide");
})

$(document).on("click", ".send-supv-reminder-button", function(){
  $('#supervisor-reminder-modal').modal("hide");
})

$(document).on("change", ".general-document-search-field", function(){
  $('.general-document-search-form').submit();
})