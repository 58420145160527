$(document).on 'click', '.roles-checkbox', ->
  if $(this).is(':checked')
    checked = true
  else
    checked = false
  $(this).siblings('.checkbox-role-ids').val($(this).val())
  $(this).siblings('.checkbox-role-verify').val(checked)
  return

$(document).on 'click', '.re-invite-btn', (e) ->
  e.preventDefault()
  url = $(this).data('url')
  if $(this).data('disable')
    return
  $(this).attr 'data-disable', true
  $('#loader-cont').show()
  $.ajax
    url: url
    type: 'PUT'
    complete: ->
      $(this).attr 'data-disable', false
      return
  return
