$.browser = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()))

$(document).on 'click', '.layman-invite-btn', (e) ->
  e.preventDefault()
  $('#loader-cont').show()
  Rails.fire($(this).closest('form')[0], 'submit')
  return

if(!$.browser)
  $(document).on 'click', '.delegated-preaching-row', ->
    url = $(this).data('url')
    location.href = url;
    return
if($.browser)
  $(document).on 'touchend', '.delegated-preaching-row', ->
    url = $(this).data('url')
    location.href = url;
    return
